// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-conference-js": () => import("./../../../src/pages/conference.js" /* webpackChunkName: "component---src-pages-conference-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-contact-thanks-js": () => import("./../../../src/pages/contact/thanks.js" /* webpackChunkName: "component---src-pages-contact-thanks-js" */),
  "component---src-pages-for-students-js": () => import("./../../../src/pages/for-students.js" /* webpackChunkName: "component---src-pages-for-students-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-work-course-selection-js": () => import("./../../../src/pages/work/course-selection.js" /* webpackChunkName: "component---src-pages-work-course-selection-js" */),
  "component---src-pages-work-idea-board-js": () => import("./../../../src/pages/work/idea-board.js" /* webpackChunkName: "component---src-pages-work-idea-board-js" */),
  "component---src-pages-work-index-js": () => import("./../../../src/pages/work/index.js" /* webpackChunkName: "component---src-pages-work-index-js" */),
  "component---src-pages-work-room-draw-js": () => import("./../../../src/pages/work/room-draw.js" /* webpackChunkName: "component---src-pages-work-room-draw-js" */),
  "component---src-pages-work-tigerbook-js": () => import("./../../../src/pages/work/tigerbook.js" /* webpackChunkName: "component---src-pages-work-tigerbook-js" */)
}

